.index-banner {
  width: 100%;
  height: 100vh;
}

.index-banner .index-banner-slide .swiper-wrapper .swiper-slide {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.index-banner .index-banner-slide .swiper-wrapper .swiper-slide.swiper-slide-active .container h1 span, .index-banner .index-banner-slide .swiper-wrapper .swiper-slide.swiper-slide-active .container h2 span {
  transform: translateY(0);
}

.index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container {
  height: 100%;
  display: flex;
  align-items: flex-start;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container {
    padding-bottom: 20%;
  }
}

.index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container h1, .index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container h2 {
  display: block;
  width: 100%;
  text-align: left;
}

@media (max-width: 767px) {
  .index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container h1, .index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container h2 {
    text-align: center;
  }
}

.index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container h1 span, .index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container h2 span {
  display: block;
  transition: 1s cubic-bezier(0.25, 0.74, 0.22, 0.99);
  transform: translateY(100%);
}

.index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container h1 {
  overflow: hidden;
  font-weight: bold;
  font-size: 66px;
}

@media (max-width: 1600px) {
  .index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container h1 {
    font-size: 58.8px;
  }
}

@media (max-width: 1359px) {
  .index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container h1 {
    font-size: 51.6px;
  }
}

@media (max-width: 1024px) {
  .index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container h1 {
    font-size: 44.4px;
  }
}

@media (max-width: 991px) {
  .index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container h1 {
    font-size: 37.2px;
  }
}

@media (max-width: 767px) {
  .index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container h1 {
    font-size: 30px;
  }
}

.index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container h1 span {
  transition-delay: .3s;
}

.index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container h2 {
  overflow: hidden;
  line-height: 1.6;
  font-size: 22px;
}

@media (max-width: 1600px) {
  .index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container h2 {
    font-size: 20px;
  }
}

@media (max-width: 1359px) {
  .index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container h2 {
    font-size: 18px;
  }
}

@media (max-width: 1024px) {
  .index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container h2 {
    font-size: 16px;
  }
}

.index-banner .index-banner-slide .swiper-wrapper .swiper-slide .container h2 span {
  transition-delay: .6s;
}

.index-banner .index-banner-slide .swiper-wrapper .swiper-slide a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.index-banner .index-banner-slide .swiper-wrapper .swiper-slide a video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.index-banner .index-banner-slide .swiper-wrapper .swiper-slide a .playVideo {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 99999;
  color: #eee;
  font-size: 140px;
}

@media (max-width: 1600px) {
  .index-banner .index-banner-slide .swiper-wrapper .swiper-slide a .playVideo {
    font-size: 122px;
  }
}

@media (max-width: 1359px) {
  .index-banner .index-banner-slide .swiper-wrapper .swiper-slide a .playVideo {
    font-size: 104px;
  }
}

@media (max-width: 1024px) {
  .index-banner .index-banner-slide .swiper-wrapper .swiper-slide a .playVideo {
    font-size: 86px;
  }
}

@media (max-width: 991px) {
  .index-banner .index-banner-slide .swiper-wrapper .swiper-slide a .playVideo {
    font-size: 68px;
  }
}

@media (max-width: 767px) {
  .index-banner .index-banner-slide .swiper-wrapper .swiper-slide a .playVideo {
    font-size: 50px;
  }
}

.index-banner .index-banner-slide .swiper-wrapper .swiper-slide a.pc-banner {
  display: block;
}

@media (max-width: 991px) {
  .index-banner .index-banner-slide .swiper-wrapper .swiper-slide a.pc-banner {
    display: none;
  }
}

.index-banner .index-banner-slide .swiper-wrapper .swiper-slide a.mob-banner {
  display: none;
}

@media (max-width: 991px) {
  .index-banner .index-banner-slide .swiper-wrapper .swiper-slide a.mob-banner {
    display: block;
  }
}

.index-banner .index-banner-slide .swiper-wrapper .swiper-slide img {
  display: block;
  width: 100%;
  height: auto;
}

.index-banner .index-banner-slide .swiper-pagination {
  display: flex;
  align-items: center;
  bottom: 0;
  left: auto;
  right: 50px;
  height: 100%;
  width: 80px;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 991px) {
  .index-banner .index-banner-slide .swiper-pagination {
    bottom: 10px;
  }
}

@media (max-width: 767px) {
  .index-banner .index-banner-slide .swiper-pagination {
    bottom: 20%;
  }
}

@media (max-width: 991px) {
  .index-banner .index-banner-slide .swiper-pagination {
    left: 0;
  }
}

@media (max-width: 991px) {
  .index-banner .index-banner-slide .swiper-pagination {
    right: 0;
  }
}

@media (max-width: 991px) {
  .index-banner .index-banner-slide .swiper-pagination {
    height: 50px;
  }
}

@media (max-width: 991px) {
  .index-banner .index-banner-slide .swiper-pagination {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .index-banner .index-banner-slide .swiper-pagination {
    flex-direction: row;
  }
}

@media (max-width: 991px) {
  .index-banner .index-banner-slide .swiper-pagination {
    justify-content: flex-start;
  }
}

@media (max-width: 767px) {
  .index-banner .index-banner-slide .swiper-pagination {
    justify-content: center;
  }
}

.index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet {
  opacity: 1;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  outline: none;
  margin: 10px 0;
  width: 80px;
  height: 80px;
}

@media (max-width: 991px) {
  .index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet {
    margin: 0 10px;
  }
}

@media (max-width: 1024px) {
  .index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet {
    width: 60px;
  }
}

@media (max-width: 991px) {
  .index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet {
    width: 40px;
  }
}

@media (max-width: 1024px) {
  .index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet {
    height: 60px;
  }
}

@media (max-width: 991px) {
  .index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet {
    height: 40px;
  }
}

.index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet::after {
  content: '';
  display: inline-block;
  border-radius: 50%;
  background-color: #fff;
  width: 6px;
  height: 6px;
}

@media (max-width: 767px) {
  .index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet::after {
    width: 5px;
  }
}

@media (max-width: 767px) {
  .index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet::after {
    height: 5px;
  }
}

.index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet svg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet svg rect {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  stroke-width: 1;
  stroke: rgba(255, 255, 255, 0.3);
  fill: none;
  rx: 50;
  ry: 50;
  x: 1;
  y: 1;
}

.index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet svg rect + rect {
  stroke: white;
  stroke-dasharray: 245px;
  stroke-dashoffset: 245px;
}

@media (max-width: 1024px) {
  .index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet svg rect + rect {
    stroke-dasharray: 182px;
  }
}

@media (max-width: 991px) {
  .index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet svg rect + rect {
    stroke-dasharray: 120px;
  }
}

@media (max-width: 1024px) {
  .index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet svg rect + rect {
    stroke-dashoffset: 182px;
  }
}

@media (max-width: 991px) {
  .index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet svg rect + rect {
    stroke-dashoffset: 120px;
  }
}

.index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active svg {
  opacity: 1;
}

.index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active svg rect + rect {
  animation: 5s progress linear;
  animation: 5s progress linear;
}

@media (max-width: 1024px) {
  .index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active svg rect + rect {
    animation: 5s progress2 linear;
  }
}

@media (max-width: 991px) {
  .index-banner .index-banner-slide .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active svg rect + rect {
    animation: 5s progress3 linear;
  }
}

.index-banner .index-banner-scroll-icon {
  position: absolute;
  z-index: 1;
  color: #fff;
  left: 70px;
  bottom: 70px;
  display: block;
}

@media (max-width: 1600px) {
  .index-banner .index-banner-scroll-icon {
    left: 60px;
  }
}

@media (max-width: 1359px) {
  .index-banner .index-banner-scroll-icon {
    left: 50px;
  }
}

@media (max-width: 1024px) {
  .index-banner .index-banner-scroll-icon {
    left: 40px;
  }
}

@media (max-width: 991px) {
  .index-banner .index-banner-scroll-icon {
    left: 30px;
  }
}

@media (max-width: 767px) {
  .index-banner .index-banner-scroll-icon {
    left: 20px;
  }
}

@media (max-width: 1600px) {
  .index-banner .index-banner-scroll-icon {
    bottom: 62px;
  }
}

@media (max-width: 1359px) {
  .index-banner .index-banner-scroll-icon {
    bottom: 54px;
  }
}

@media (max-width: 1024px) {
  .index-banner .index-banner-scroll-icon {
    bottom: 46px;
  }
}

@media (max-width: 991px) {
  .index-banner .index-banner-scroll-icon {
    bottom: 38px;
  }
}

@media (max-width: 767px) {
  .index-banner .index-banner-scroll-icon {
    bottom: 30px;
  }
}

@media (max-width: 991px) {
  .index-banner .index-banner-scroll-icon {
    display: none;
  }
}

.index-banner .index-banner-scroll-icon::before {
  content: '';
  display: block;
  width: 1px;
  height: 55px;
  background: #fff;
  margin: 0 auto;
  margin-bottom: 10px;
}

.index-banner .index-banner-scroll-icon span {
  writing-mode: tb-rl;
  font-size: 12px;
  animation: 1s rain infinite;
}

.index-banner .index-banner-news {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 390px;
}

@media (max-width: 767px) {
  .index-banner .index-banner-news {
    width: 100%;
  }
}

.index-banner .index-banner-news > div {
  box-sizing: border-box;
  background: #263159;
  width: 390px;
  border-top-left-radius: 50px;
  padding: 45px 45px 35px 45px;
}

@media (max-width: 767px) {
  .index-banner .index-banner-news > div {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index-banner .index-banner-news > div {
    border-top-left-radius: 0;
  }
}

@media (max-width: 1024px) {
  .index-banner .index-banner-news > div {
    padding: 25px 25px 15px 35px;
  }
}

@media (max-width: 767px) {
  .index-banner .index-banner-news > div {
    padding: 15px;
  }
}

.index-banner .index-banner-news .index-banner-news-title {
  color: #b0c258;
  font-size: 14px;
  margin-bottom: 10px;
}

.index-banner .index-banner-news .index-banner-news-slide .swiper-container {
  height: 50px;
}

.index-banner .index-banner-news .index-banner-news-slide .swiper-slide a {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  transition: 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99);
  color: #fff;
  line-height: 1.6;
}

.index-banner .index-banner-news .index-banner-news-slide .swiper-slide a:hover {
  color: #b0c258;
}

.section1 {
  position: relative;
  z-index: 1;
  background-color: #fff;
  overflow: hidden;
  padding-top: 120px;
  padding-bottom: 140px;
}

@media (max-width: 1600px) {
  .section1 {
    padding-top: 102px;
  }
}

@media (max-width: 1359px) {
  .section1 {
    padding-top: 84px;
  }
}

@media (max-width: 1024px) {
  .section1 {
    padding-top: 66px;
  }
}

@media (max-width: 991px) {
  .section1 {
    padding-top: 48px;
  }
}

@media (max-width: 767px) {
  .section1 {
    padding-top: 30px;
  }
}

@media (max-width: 1600px) {
  .section1 {
    padding-bottom: 120px;
  }
}

@media (max-width: 1359px) {
  .section1 {
    padding-bottom: 100px;
  }
}

@media (max-width: 1024px) {
  .section1 {
    padding-bottom: 80px;
  }
}

@media (max-width: 991px) {
  .section1 {
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .section1 {
    padding-bottom: 40px;
  }
}

.section1 .animate-circle {
  position: absolute;
  left: 0;
  bottom: 0;
  top: auto;
  transform: translate3d(-50%, 40%, 0);
}

@media (max-width: 991px) {
  .section1 .animate-circle {
    bottom: auto;
  }
}

@media (max-width: 991px) {
  .section1 .animate-circle {
    top: 0;
  }
}

@media (max-width: 991px) {
  .section1 .animate-circle {
    transform: translate3d(-50%, 10%, 0);
  }
}

.section1 .animate-circle svg {
  width: 600px;
  height: 600px;
}

@media (max-width: 1024px) {
  .section1 .animate-circle svg {
    width: 500px;
  }
}

@media (max-width: 767px) {
  .section1 .animate-circle svg {
    width: 400px;
  }
}

@media (max-width: 1024px) {
  .section1 .animate-circle svg {
    height: 500px;
  }
}

@media (max-width: 767px) {
  .section1 .animate-circle svg {
    height: 400px;
  }
}

.section1 .animate-circle svg rect {
  stroke: #f1f4f7;
  stroke-dasharray: 90%;
  stroke-dashoffset: 90%;
  stroke-width: 80;
  x: 40;
  y: 40;
  width: calc(100% - 80px);
  height: calc(100% - 80px);
}

@media (max-width: 1024px) {
  .section1 .animate-circle svg rect {
    stroke-width: 60;
  }
}

@media (max-width: 767px) {
  .section1 .animate-circle svg rect {
    stroke-width: 40;
  }
}

@media (max-width: 1024px) {
  .section1 .animate-circle svg rect {
    x: 30;
  }
}

@media (max-width: 767px) {
  .section1 .animate-circle svg rect {
    x: 20;
  }
}

@media (max-width: 1024px) {
  .section1 .animate-circle svg rect {
    y: 30;
  }
}

@media (max-width: 767px) {
  .section1 .animate-circle svg rect {
    y: 20;
  }
}

@media (max-width: 1024px) {
  .section1 .animate-circle svg rect {
    width: calc(100% - 60px);
  }
}

@media (max-width: 767px) {
  .section1 .animate-circle svg rect {
    width: calc(100% - 40px);
  }
}

@media (max-width: 1024px) {
  .section1 .animate-circle svg rect {
    height: calc(100% - 60px);
  }
}

@media (max-width: 767px) {
  .section1 .animate-circle svg rect {
    height: calc(100% - 40px);
  }
}

.section1 .animate-circle.active svg rect {
  stroke-dashoffset: 0%;
}

.section1 .logo-fixed-img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.section1 .logo-fixed-img img {
  display: block;
  height: auto;
}

@media (max-width: 1024px) {
  .section1 .logo-fixed-img img {
    width: 400px;
  }
}

@media (max-width: 767px) {
  .section1 .logo-fixed-img img {
    width: 200px;
  }
}

.section1 .container {
  position: relative;
  z-index: 1;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 991px) {
  .section1 .container {
    display: block;
  }
}

.section1 .container .left {
  flex-shrink: 0;
  margin-bottom: 0;
  width: 43.57%;
}

@media (max-width: 991px) {
  .section1 .container .left {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .section1 .container .left {
    margin-bottom: 10px;
  }
}

@media (max-width: 991px) {
  .section1 .container .left {
    width: 100%;
  }
}

.section1 .container .left h1 {
  font-weight: bold;
  color: #263159;
  overflow: hidden;
  text-align: left;
  margin-bottom: 25px;
  font-size: 50px;
}

@media (max-width: 991px) {
  .section1 .container .left h1 {
    text-align: center;
  }
}

@media (max-width: 1600px) {
  .section1 .container .left h1 {
    margin-bottom: 23px;
  }
}

@media (max-width: 1359px) {
  .section1 .container .left h1 {
    margin-bottom: 21px;
  }
}

@media (max-width: 1024px) {
  .section1 .container .left h1 {
    margin-bottom: 19px;
  }
}

@media (max-width: 991px) {
  .section1 .container .left h1 {
    margin-bottom: 17px;
  }
}

@media (max-width: 767px) {
  .section1 .container .left h1 {
    margin-bottom: 15px;
  }
}

@media (max-width: 1600px) {
  .section1 .container .left h1 {
    font-size: 40px;
  }
}

@media (max-width: 1359px) {
  .section1 .container .left h1 {
    font-size: 36px;
  }
}

@media (max-width: 1024px) {
  .section1 .container .left h1 {
    font-size: 24px;
  }
}

.section1 .container .left .text {
  overflow: hidden;
}

.section1 .container .left .common-more-link {
  margin-top: 180px;
}

@media (max-width: 1600px) {
  .section1 .container .left .common-more-link {
    margin-top: 100px;
  }
}

@media (max-width: 1359px) {
  .section1 .container .left .common-more-link {
    margin-top: 70px;
  }
}

@media (max-width: 1024px) {
  .section1 .container .left .common-more-link {
    margin-top: 50px;
  }
}

@media (max-width: 991px) {
  .section1 .container .left .common-more-link {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .section1 .container .left .common-more-link {
    margin-top: 20px;
  }
}

@media (max-width: 991px) {
  .section1 .container .left .common-more-link a {
    margin: 0 auto;
  }
}

.section1 .container .right {
  flex-shrink: 0;
  width: 50%;
}

@media (max-width: 991px) {
  .section1 .container .right {
    width: 100%;
  }
}

.section1 .container .right img {
  display: block;
  width: 100%;
  height: auto;
  box-shadow: 20px 20px 0 #b0c258;
}

@media (max-width: 991px) {
  .section1 .container .right img {
    box-shadow: 10px 10px 0 #b0c258;
  }
}

@media (max-width: 767px) {
  .section1 .container .right img {
    box-shadow: 5px 5px 0 #b0c258;
  }
}

.section2 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: #263159;
  padding-top: 110px;
  padding-bottom: 110px;
}

@media (max-width: 1600px) {
  .section2 {
    padding-top: 98px;
  }
}

@media (max-width: 1359px) {
  .section2 {
    padding-top: 86px;
  }
}

@media (max-width: 1024px) {
  .section2 {
    padding-top: 74px;
  }
}

@media (max-width: 991px) {
  .section2 {
    padding-top: 62px;
  }
}

@media (max-width: 767px) {
  .section2 {
    padding-top: 50px;
  }
}

@media (max-width: 1600px) {
  .section2 {
    padding-bottom: 96px;
  }
}

@media (max-width: 1359px) {
  .section2 {
    padding-bottom: 82px;
  }
}

@media (max-width: 1024px) {
  .section2 {
    padding-bottom: 68px;
  }
}

@media (max-width: 991px) {
  .section2 {
    padding-bottom: 54px;
  }
}

@media (max-width: 767px) {
  .section2 {
    padding-bottom: 40px;
  }
}

.section2 .logo-fixed-img {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  transform: translateY(-100%);
}

.section2 .logo-fixed-img img {
  display: block;
  height: auto;
}

@media (max-width: 1024px) {
  .section2 .logo-fixed-img img {
    width: 400px;
  }
}

@media (max-width: 767px) {
  .section2 .logo-fixed-img img {
    width: 200px;
  }
}

.section2 .section2-title {
  font-weight: bold;
  color: #fff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 40px;
  font-size: 50px;
}

@media (max-width: 1600px) {
  .section2 .section2-title {
    margin-bottom: 36px;
  }
}

@media (max-width: 1359px) {
  .section2 .section2-title {
    margin-bottom: 32px;
  }
}

@media (max-width: 1024px) {
  .section2 .section2-title {
    margin-bottom: 28px;
  }
}

@media (max-width: 991px) {
  .section2 .section2-title {
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .section2 .section2-title {
    margin-bottom: 20px;
  }
}

@media (max-width: 1600px) {
  .section2 .section2-title {
    font-size: 40px;
  }
}

@media (max-width: 1359px) {
  .section2 .section2-title {
    font-size: 36px;
  }
}

@media (max-width: 1024px) {
  .section2 .section2-title {
    font-size: 24px;
  }
}

.section2 .section2-cont {
  align-items: stretch;
  display: flex;
}

@media (max-width: 991px) {
  .section2 .section2-cont {
    display: block;
  }
}

.section2 .section2-cont .section2-cont-item {
  flex-shrink: 0;
  background: #313c61;
  box-sizing: border-box;
  color: #fff;
  position: relative;
  z-index: 1;
  width: 50%;
  padding: 80px;
}

@media (max-width: 991px) {
  .section2 .section2-cont .section2-cont-item {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .section2 .section2-cont .section2-cont-item {
    padding: 70px;
  }
}

@media (max-width: 1359px) {
  .section2 .section2-cont .section2-cont-item {
    padding: 60px;
  }
}

@media (max-width: 1024px) {
  .section2 .section2-cont .section2-cont-item {
    padding: 50px;
  }
}

@media (max-width: 991px) {
  .section2 .section2-cont .section2-cont-item {
    padding: 40px;
  }
}

@media (max-width: 767px) {
  .section2 .section2-cont .section2-cont-item {
    padding: 30px;
  }
}

.section2 .section2-cont .section2-cont-item::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99);
}

.section2 .section2-cont .section2-cont-item.active {
  z-index: 2;
}

.section2 .section2-cont .section2-cont-item.active::after {
  background: #3c466a;
  transform: scale(1.1);
  transform: scale(1.1);
}

@media (max-width: 991px) {
  .section2 .section2-cont .section2-cont-item.active::after {
    transform: scale(1.05);
  }
}

.section2 .section2-cont .section2-cont-item.active .item-title .right-icon::after {
  transform: scale(1);
}

.section2 .section2-cont .section2-cont-item .item-title {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

@media (max-width: 1600px) {
  .section2 .section2-cont .section2-cont-item .item-title {
    margin-bottom: 45px;
  }
}

@media (max-width: 1359px) {
  .section2 .section2-cont .section2-cont-item .item-title {
    margin-bottom: 40px;
  }
}

@media (max-width: 1024px) {
  .section2 .section2-cont .section2-cont-item .item-title {
    margin-bottom: 35px;
  }
}

@media (max-width: 991px) {
  .section2 .section2-cont .section2-cont-item .item-title {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .section2 .section2-cont .section2-cont-item .item-title {
    margin-bottom: 25px;
  }
}

.section2 .section2-cont .section2-cont-item .item-title .left-text {
  flex-shrink: 0;
}

.section2 .section2-cont .section2-cont-item .item-title .left-text h1 {
  font-weight: bold;
  font-size: 36px;
}

@media (max-width: 1600px) {
  .section2 .section2-cont .section2-cont-item .item-title .left-text h1 {
    font-size: 32px;
  }
}

@media (max-width: 1359px) {
  .section2 .section2-cont .section2-cont-item .item-title .left-text h1 {
    font-size: 28px;
  }
}

@media (max-width: 1024px) {
  .section2 .section2-cont .section2-cont-item .item-title .left-text h1 {
    font-size: 20px;
  }
}

.section2 .section2-cont .section2-cont-item .item-title .left-text h2 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
}

@media (max-width: 1600px) {
  .section2 .section2-cont .section2-cont-item .item-title .left-text h2 {
    font-size: 16px;
  }
}

@media (max-width: 1359px) {
  .section2 .section2-cont .section2-cont-item .item-title .left-text h2 {
    font-size: 14px;
  }
}

.section2 .section2-cont .section2-cont-item .item-title .right-icon {
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99);
  width: 90px;
  height: 90px;
}

@media (max-width: 1600px) {
  .section2 .section2-cont .section2-cont-item .item-title .right-icon {
    width: 84px;
  }
}

@media (max-width: 1359px) {
  .section2 .section2-cont .section2-cont-item .item-title .right-icon {
    width: 78px;
  }
}

@media (max-width: 1024px) {
  .section2 .section2-cont .section2-cont-item .item-title .right-icon {
    width: 72px;
  }
}

@media (max-width: 991px) {
  .section2 .section2-cont .section2-cont-item .item-title .right-icon {
    width: 66px;
  }
}

@media (max-width: 767px) {
  .section2 .section2-cont .section2-cont-item .item-title .right-icon {
    width: 60px;
  }
}

@media (max-width: 1600px) {
  .section2 .section2-cont .section2-cont-item .item-title .right-icon {
    height: 84px;
  }
}

@media (max-width: 1359px) {
  .section2 .section2-cont .section2-cont-item .item-title .right-icon {
    height: 78px;
  }
}

@media (max-width: 1024px) {
  .section2 .section2-cont .section2-cont-item .item-title .right-icon {
    height: 72px;
  }
}

@media (max-width: 991px) {
  .section2 .section2-cont .section2-cont-item .item-title .right-icon {
    height: 66px;
  }
}

@media (max-width: 767px) {
  .section2 .section2-cont .section2-cont-item .item-title .right-icon {
    height: 60px;
  }
}

.section2 .section2-cont .section2-cont-item .item-title .right-icon::before {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  border-radius: 50%;
  border: solid #fff;
  border-width: 2px;
}

@media (max-width: 767px) {
  .section2 .section2-cont .section2-cont-item .item-title .right-icon::before {
    border-width: 1px;
  }
}

.section2 .section2-cont .section2-cont-item .item-title .right-icon::after {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  background-color: #b0c258;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transform: scale(0);
  transform-origin: center;
  transition: 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99);
  border-radius: 50%;
}

.section2 .section2-cont .section2-cont-item .item-title .right-icon img {
  position: relative;
  z-index: 2;
  height: auto;
  width: 54px;
}

@media (max-width: 1600px) {
  .section2 .section2-cont .section2-cont-item .item-title .right-icon img {
    width: 50.2px;
  }
}

@media (max-width: 1359px) {
  .section2 .section2-cont .section2-cont-item .item-title .right-icon img {
    width: 46.4px;
  }
}

@media (max-width: 1024px) {
  .section2 .section2-cont .section2-cont-item .item-title .right-icon img {
    width: 42.6px;
  }
}

@media (max-width: 991px) {
  .section2 .section2-cont .section2-cont-item .item-title .right-icon img {
    width: 38.8px;
  }
}

@media (max-width: 767px) {
  .section2 .section2-cont .section2-cont-item .item-title .right-icon img {
    width: 35px;
  }
}

.section2 .section2-cont .section2-cont-item .item-cont {
  position: relative;
  z-index: 1;
}

.section2 .section2-cont .section2-cont-item .item-cont ul li {
  overflow: hidden;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
}

@media (max-width: 1600px) {
  .section2 .section2-cont .section2-cont-item .item-cont ul li {
    padding-top: 13.4px;
  }
}

@media (max-width: 1359px) {
  .section2 .section2-cont .section2-cont-item .item-cont ul li {
    padding-top: 11.8px;
  }
}

@media (max-width: 1024px) {
  .section2 .section2-cont .section2-cont-item .item-cont ul li {
    padding-top: 10.2px;
  }
}

@media (max-width: 991px) {
  .section2 .section2-cont .section2-cont-item .item-cont ul li {
    padding-top: 8.6px;
  }
}

@media (max-width: 767px) {
  .section2 .section2-cont .section2-cont-item .item-cont ul li {
    padding-top: 7px;
  }
}

@media (max-width: 1600px) {
  .section2 .section2-cont .section2-cont-item .item-cont ul li {
    padding-bottom: 13.4px;
  }
}

@media (max-width: 1359px) {
  .section2 .section2-cont .section2-cont-item .item-cont ul li {
    padding-bottom: 11.8px;
  }
}

@media (max-width: 1024px) {
  .section2 .section2-cont .section2-cont-item .item-cont ul li {
    padding-bottom: 10.2px;
  }
}

@media (max-width: 991px) {
  .section2 .section2-cont .section2-cont-item .item-cont ul li {
    padding-bottom: 8.6px;
  }
}

@media (max-width: 767px) {
  .section2 .section2-cont .section2-cont-item .item-cont ul li {
    padding-bottom: 7px;
  }
}

@media (max-width: 1600px) {
  .section2 .section2-cont .section2-cont-item .item-cont ul li {
    font-size: 16px;
  }
}

@media (max-width: 1359px) {
  .section2 .section2-cont .section2-cont-item .item-cont ul li {
    font-size: 14px;
  }
}

.section2 .section2-cont .section2-cont-item .item-cont ul li .iconfont {
  color: #b0c258;
  margin-left: 10px;
  margin-right: 25px;
  font-size: 24px;
}

@media (max-width: 1600px) {
  .section2 .section2-cont .section2-cont-item .item-cont ul li .iconfont {
    font-size: 23.2px;
  }
}

@media (max-width: 1359px) {
  .section2 .section2-cont .section2-cont-item .item-cont ul li .iconfont {
    font-size: 22.4px;
  }
}

@media (max-width: 1024px) {
  .section2 .section2-cont .section2-cont-item .item-cont ul li .iconfont {
    font-size: 21.6px;
  }
}

@media (max-width: 991px) {
  .section2 .section2-cont .section2-cont-item .item-cont ul li .iconfont {
    font-size: 20.8px;
  }
}

@media (max-width: 767px) {
  .section2 .section2-cont .section2-cont-item .item-cont ul li .iconfont {
    font-size: 20px;
  }
}

.section3 {
  position: relative;
  z-index: 1;
  background: #f1f4f7;
  padding-top: 110px;
  padding-bottom: 220px;
}

@media (max-width: 1600px) {
  .section3 {
    padding-top: 94px;
  }
}

@media (max-width: 1359px) {
  .section3 {
    padding-top: 78px;
  }
}

@media (max-width: 1024px) {
  .section3 {
    padding-top: 62px;
  }
}

@media (max-width: 991px) {
  .section3 {
    padding-top: 46px;
  }
}

@media (max-width: 767px) {
  .section3 {
    padding-top: 30px;
  }
}

@media (max-width: 1600px) {
  .section3 {
    padding-bottom: 176px;
  }
}

@media (max-width: 1359px) {
  .section3 {
    padding-bottom: 132px;
  }
}

@media (max-width: 1024px) {
  .section3 {
    padding-bottom: 88px;
  }
}

@media (max-width: 991px) {
  .section3 {
    padding-bottom: 44px;
  }
}

@media (max-width: 767px) {
  .section3 {
    padding-bottom: 0px;
  }
}

.section3 .section3-img {
  display: block;
  top: 0;
  right: 0;
  z-index: 0;
  width: auto;
  height: 100%;
  position: absolute;
}

@media (max-width: 767px) {
  .section3 .section3-img {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section3 .section3-img {
    height: auto;
  }
}

@media (max-width: 767px) {
  .section3 .section3-img {
    position: static;
  }
}

.section3 .container {
  position: relative;
  z-index: 1;
}

.section3 .section3-title {
  color: #263159;
  font-weight: bold;
  font-size: 50px;
  margin-bottom: 85px;
}

@media (max-width: 1600px) {
  .section3 .section3-title {
    font-size: 40px;
  }
}

@media (max-width: 1359px) {
  .section3 .section3-title {
    font-size: 36px;
  }
}

@media (max-width: 1024px) {
  .section3 .section3-title {
    font-size: 24px;
  }
}

@media (max-width: 1600px) {
  .section3 .section3-title {
    margin-bottom: 70px;
  }
}

@media (max-width: 1359px) {
  .section3 .section3-title {
    margin-bottom: 55px;
  }
}

@media (max-width: 1024px) {
  .section3 .section3-title {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .section3 .section3-title {
    margin-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .section3 .section3-title {
    margin-bottom: 10px;
  }
}

.section3 .section3-cont ul li {
  overflow: hidden;
  margin-bottom: 40px;
}

@media (max-width: 1600px) {
  .section3 .section3-cont ul li {
    margin-bottom: 34px;
  }
}

@media (max-width: 1359px) {
  .section3 .section3-cont ul li {
    margin-bottom: 28px;
  }
}

@media (max-width: 1024px) {
  .section3 .section3-cont ul li {
    margin-bottom: 22px;
  }
}

@media (max-width: 991px) {
  .section3 .section3-cont ul li {
    margin-bottom: 16px;
  }
}

@media (max-width: 767px) {
  .section3 .section3-cont ul li {
    margin-bottom: 10px;
  }
}

.section3 .section3-cont ul li .item-title {
  font-weight: bold;
  color: #263159;
  display: flex;
  align-items: center;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .section3 .section3-cont ul li .item-title {
    font-size: 18px;
  }
}

@media (max-width: 1359px) {
  .section3 .section3-cont ul li .item-title {
    font-size: 16px;
  }
}

.section3 .section3-cont ul li .item-title .iconfont {
  color: #b0c258;
  margin-right: 15px;
  margin-left: 3px;
  font-size: 30px;
}

@media (max-width: 1600px) {
  .section3 .section3-cont ul li .item-title .iconfont {
    font-size: 28px;
  }
}

@media (max-width: 1359px) {
  .section3 .section3-cont ul li .item-title .iconfont {
    font-size: 26px;
  }
}

@media (max-width: 1024px) {
  .section3 .section3-cont ul li .item-title .iconfont {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .section3 .section3-cont ul li .item-title .iconfont {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .section3 .section3-cont ul li .item-title .iconfont {
    font-size: 20px;
  }
}

.section4 {
  position: relative;
  z-index: 1;
  background-color: #fff;
  padding-top: 110px;
}

@media (max-width: 1600px) {
  .section4 {
    padding-top: 94px;
  }
}

@media (max-width: 1359px) {
  .section4 {
    padding-top: 78px;
  }
}

@media (max-width: 1024px) {
  .section4 {
    padding-top: 62px;
  }
}

@media (max-width: 991px) {
  .section4 {
    padding-top: 46px;
  }
}

@media (max-width: 767px) {
  .section4 {
    padding-top: 30px;
  }
}

.section4 .section4-title h1 {
  color: #263159;
  font-weight: bold;
  font-size: 50px;
}

@media (max-width: 1600px) {
  .section4 .section4-title h1 {
    font-size: 40px;
  }
}

@media (max-width: 1359px) {
  .section4 .section4-title h1 {
    font-size: 36px;
  }
}

@media (max-width: 1024px) {
  .section4 .section4-title h1 {
    font-size: 24px;
  }
}

.section4 .section4-title .common-more-link {
  margin-top: -35px;
}

@media (max-width: 991px) {
  .section4 .section4-title .common-more-link {
    margin-top: -45px;
  }
}

.section4 .section4-title .common-more-link > div {
  display: flex;
  justify-content: flex-end;
}

.section4 .section4-cont {
  position: relative;
  margin-top: 65px;
}

@media (max-width: 1600px) {
  .section4 .section4-cont {
    margin-top: 58px;
  }
}

@media (max-width: 1359px) {
  .section4 .section4-cont {
    margin-top: 51px;
  }
}

@media (max-width: 1024px) {
  .section4 .section4-cont {
    margin-top: 44px;
  }
}

@media (max-width: 991px) {
  .section4 .section4-cont {
    margin-top: 37px;
  }
}

@media (max-width: 767px) {
  .section4 .section4-cont {
    margin-top: 30px;
  }
}

.section4 .section4-cont .section4-slide-control {
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

@media (max-width: 767px) {
  .section4 .section4-cont .section4-slide-control {
    position: static;
  }
}

.section4 .section4-cont .section4-slide-control .container {
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  display: block;
}

@media (max-width: 767px) {
  .section4 .section4-cont .section4-slide-control .container {
    flex-direction: row-reverse;
  }
}

@media (max-width: 767px) {
  .section4 .section4-cont .section4-slide-control .container {
    display: flex;
  }
}

.section4 .section4-cont .section4-slide-control .slide-arrow {
  display: flex;
  align-items: center;
  padding-top: 35px;
}

@media (max-width: 1600px) {
  .section4 .section4-cont .section4-slide-control .slide-arrow {
    padding-top: 31px;
  }
}

@media (max-width: 1359px) {
  .section4 .section4-cont .section4-slide-control .slide-arrow {
    padding-top: 27px;
  }
}

@media (max-width: 1024px) {
  .section4 .section4-cont .section4-slide-control .slide-arrow {
    padding-top: 23px;
  }
}

@media (max-width: 991px) {
  .section4 .section4-cont .section4-slide-control .slide-arrow {
    padding-top: 19px;
  }
}

@media (max-width: 767px) {
  .section4 .section4-cont .section4-slide-control .slide-arrow {
    padding-top: 15px;
  }
}

.section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-next, .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-prev {
  color: #626262;
  font-size: 12px;
  left: auto;
  margin-top: 0;
  width: 60px;
  height: 60px;
}

@media (max-width: 1600px) {
  .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-next, .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-prev {
    width: 56px;
  }
}

@media (max-width: 1359px) {
  .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-next, .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-prev {
    width: 52px;
  }
}

@media (max-width: 1024px) {
  .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-next, .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-prev {
    width: 48px;
  }
}

@media (max-width: 991px) {
  .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-next, .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-prev {
    width: 44px;
  }
}

@media (max-width: 767px) {
  .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-next, .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-prev {
    width: 40px;
  }
}

@media (max-width: 1600px) {
  .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-next, .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-prev {
    height: 56px;
  }
}

@media (max-width: 1359px) {
  .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-next, .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-prev {
    height: 52px;
  }
}

@media (max-width: 1024px) {
  .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-next, .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-prev {
    height: 48px;
  }
}

@media (max-width: 991px) {
  .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-next, .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-prev {
    height: 44px;
  }
}

@media (max-width: 767px) {
  .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-next, .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-prev {
    height: 40px;
  }
}

.section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-next svg rect, .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-prev svg rect {
  stroke: #d2d2d2;
}

.section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-next svg rect + rect, .section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-prev svg rect + rect {
  stroke: #263159;
}

.section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-next::after {
  content: '\e621';
  font-family: 'iconfont';
}

.section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-prev {
  margin-right: 25px;
}

.section4 .section4-cont .section4-slide-control .slide-arrow .swiper-button-prev::after {
  content: '\e8dc';
  font-family: 'iconfont';
}

.section4 .section4-cont .section4-slide-control .swiper-pagination {
  position: static;
  width: 100px;
  color: #263159;
  padding-left: 10px;
  text-align: left;
  margin-top: 40px;
}

@media (max-width: 767px) {
  .section4 .section4-cont .section4-slide-control .swiper-pagination {
    margin-top: 0;
  }
}

.section4 .section4-cont .section4-slide-cont {
  margin-top: 0;
  padding-right: 0;
  padding-left: 490px;
}

@media (max-width: 767px) {
  .section4 .section4-cont .section4-slide-cont {
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  .section4 .section4-cont .section4-slide-cont {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .section4 .section4-cont .section4-slide-cont {
    padding-left: 394px;
  }
}

@media (max-width: 1359px) {
  .section4 .section4-cont .section4-slide-cont {
    padding-left: 298px;
  }
}

@media (max-width: 1024px) {
  .section4 .section4-cont .section4-slide-cont {
    padding-left: 202px;
  }
}

@media (max-width: 991px) {
  .section4 .section4-cont .section4-slide-cont {
    padding-left: 106px;
  }
}

@media (max-width: 767px) {
  .section4 .section4-cont .section4-slide-cont {
    padding-left: 10px;
  }
}

.section4 .section4-cont .section4-slide-cont .swiper-slide a {
  display: block;
  transition: 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99);
  padding: 35px;
}

@media (max-width: 1600px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a {
    padding: 31px;
  }
}

@media (max-width: 1359px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a {
    padding: 27px;
  }
}

@media (max-width: 1024px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a {
    padding: 23px;
  }
}

@media (max-width: 991px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a {
    padding: 19px;
  }
}

@media (max-width: 767px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a {
    padding: 15px;
  }
}

.section4 .section4-cont .section4-slide-cont .swiper-slide a .item-img {
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
}

@media (max-width: 1600px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-img {
    margin-bottom: 35px;
  }
}

@media (max-width: 1359px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-img {
    margin-bottom: 30px;
  }
}

@media (max-width: 1024px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-img {
    margin-bottom: 25px;
  }
}

@media (max-width: 991px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-img {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-img {
    margin-bottom: 15px;
  }
}

.section4 .section4-cont .section4-slide-cont .swiper-slide a .item-img b {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .3s;
}

.section4 .section4-cont .section4-slide-cont .swiper-slide a .item-img img {
  display: block;
  width: 100%;
  height: auto;
  opacity: 0;
}

.section4 .section4-cont .section4-slide-cont .swiper-slide a .item-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-weight: bold;
  color: #000;
  line-height: 1.33;
  transition: 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99);
  margin-bottom: 15px;
  font-size: 24px;
  min-height: 62px;
}

@media (max-width: 1600px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-title {
    margin-bottom: 13.6px;
  }
}

@media (max-width: 1359px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-title {
    margin-bottom: 12.2px;
  }
}

@media (max-width: 1024px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-title {
    margin-bottom: 10.8px;
  }
}

@media (max-width: 991px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-title {
    margin-bottom: 9.4px;
  }
}

@media (max-width: 767px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-title {
    margin-bottom: 8px;
  }
}

@media (max-width: 1600px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-title {
    font-size: 22px;
  }
}

@media (max-width: 1359px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-title {
    font-size: 18px;
  }
}

@media (max-width: 1024px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-title {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-title {
    min-height: 58px;
  }
}

@media (max-width: 1359px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-title {
    min-height: 46px;
  }
}

@media (max-width: 1024px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-title {
    min-height: 42px;
  }
}

@media (max-width: 767px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-title {
    min-height: auto;
  }
}

.section4 .section4-cont .section4-slide-cont .swiper-slide a .item-date {
  font-size: 14px;
  color: #666666;
}

.section4 .section4-cont .section4-slide-cont .swiper-slide a .item-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin-top: 10px;
  min-height: 96px;
}

@media (max-width: 1600px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-text {
    margin-top: 9px;
  }
}

@media (max-width: 1359px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-text {
    margin-top: 8px;
  }
}

@media (max-width: 1024px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-text {
    margin-top: 7px;
  }
}

@media (max-width: 991px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-text {
    margin-top: 6px;
  }
}

@media (max-width: 767px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-text {
    margin-top: 5px;
  }
}

@media (max-width: 1600px) {
  .section4 .section4-cont .section4-slide-cont .swiper-slide a .item-text {
    min-height: 84px;
  }
}

.section4 .section4-cont .section4-slide-cont .swiper-slide:hover a {
  background: #f1f4f7;
}

.section4 .section4-cont .section4-slide-cont .swiper-slide:hover a .item-title {
  color: #263159;
}

@media screen and (max-width: 1680px) {
  .section4 .section4-cont .section4-slide-cont {
    padding-left: 380px;
  }
}

@media screen and (max-width: 1600px) {
  .section4 .section4-cont .section4-slide-cont {
    padding-left: 400px;
  }
}

@media screen and (max-width: 1440px) {
  .section4 .section4-cont .section4-slide-cont {
    padding-left: 380px;
  }
}

@media screen and (max-width: 1366px) {
  .section4 .section4-cont .section4-slide-cont {
    padding-left: 340px;
  }
}

@media screen and (max-width: 1024px) {
  .section4 .section4-cont .section4-slide-cont {
    padding-left: 220px;
  }
}

@media screen and (max-width: 768px) {
  .section4 .section4-cont .section4-slide-cont {
    padding-left: 170px;
  }
}

@media screen and (max-width: 640px) {
  .section4 .section4-cont .section4-slide-cont {
    padding-left: 10px;
  }
}

@keyframes progress {
  0% {
    stroke-dashoffset: 245px;
  }
  50% {
    stroke-dashoffset: 122.5px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes progress2 {
  0% {
    stroke-dashoffset: 182px;
  }
  50% {
    stroke-dashoffset: 91px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes progress3 {
  0% {
    stroke-dashoffset: 120px;
  }
  50% {
    stroke-dashoffset: 60px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
