@import "../../assets/styles/utils";

.index-banner {
  width: 100%;
  height: 100vh;
  .index-banner-slide{
    .swiper-wrapper {
      .swiper-slide {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        // @include res(height, 100vh, (md:600px, sm:512px, xs:350px));
        &.swiper-slide-active{
          .container {
            h1,h2 {
              span {
                transform: translateY(0);
              }
            }
          }
        }
        .container{
          height: 100%;
          display: flex;
          align-items: flex-start;
          color: #fff;
          flex-direction: column;
          justify-content: center;
          @include res(padding-bottom, 0,(xs:20%));
          h1,h2{
            display: block;
            width: 100%;
            @include res(text-align,left,(xs:center));
            span{
              display: block;
              transition: 1s $anime-bezier;
              transform: translateY(100%);
            }
          }
          h1{
            overflow: hidden;
            font-weight: bold;
            @include res(font-size,66px, 30 / 66);
            span{
              transition-delay: .3s;
            }
          }
          h2{
            overflow: hidden;
            line-height: 1.6;
            @include res(font-size, $title-size-xs-base, $title-size-xs-ratio);
            span {
              transition-delay: .6s;
            }
          }
        }
        a {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          video{
            width:100%;
            height: 100%;
            object-fit:cover;
          }
          .playVideo{
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.2);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 99999;
            color: #eee;
            @include res(font-size, 140px, 50 / 140);
          }

          &.pc-banner {
            @include res(display, block, (sm:none));
          }

          &.mob-banner {
            @include res(display, none, (sm:block));
          }
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }

    .swiper-pagination {
      display: flex;
      align-items: center;
      @include res(bottom, 0, (sm:10px,xs:20%));
      @include res(left, auto, (sm:0));
      @include res(right, 50px, (sm:0));
      @include res(height, 100%, (sm:50px));
      @include res(width, 80px, (sm:100%));
      @include res(flex-direction, column, (sm:row));
      @include res(justify-content, center, (sm:flex-start, xs:center));

      .swiper-pagination-bullet {
        opacity: 1;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-shrink: 0;
        outline: none;
        @include res(margin, 10px 0, (sm:0 10px));
        @include res(width, 80px, (md: 60px, sm:40px));
        @include res(height, 80px, (md: 60px, sm:40px));

        &::after {
          content: '';
          display: inline-block;
          border-radius: 50%;
          background-color: #fff;
          @include res(width, 6px, (xs:5px));
          @include res(height, 6px, (xs:5px));
        }

        svg {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;

          rect {
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            stroke-width: 1;
            stroke: rgba(255, 255, 255, .3);
            fill: none;
            rx: 50;
            ry: 50;
            x: 1;
            y: 1;
          }

          rect+rect {
            stroke: rgba(255, 255, 255, 1);
            @include res(stroke-dasharray, 245px, (md: 182px, sm:120px));
            @include res(stroke-dashoffset, 245px, (md: 182px, sm:120px));
          }
        }

        &.swiper-pagination-bullet-active {
          svg {
            opacity: 1;

            rect+rect {
              animation: 5s progress linear;
              @include res(animation, 5s progress linear, (md: 5s progress2 linear, sm:5s progress3 linear));
            }
          }
        }
      }
    }
  }
  .index-banner-scroll-icon{
    position: absolute;
    z-index: 1;
    color: #fff;
    @include res(left,70px, 20 / 70);
    @include res(bottom, 70px, 30 / 70);
    @include res(display,block,(sm:none));
    &::before {
      content: '';
      display: block;
      width: 1px;
      height: 55px;
      background: #fff;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    span{
      writing-mode:tb-rl;
      font-size: 12px;
      animation: 1s rain infinite;
    }
  }
  .index-banner-news{
    overflow: hidden;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    @include res(width, 390px, (xs:100%));
    // @include res(position, absolute,(xs:static));
    >div{
      box-sizing: border-box;
      background: $color-blue;
      @include res(width, 390px, (xs:100%));
      @include res(border-top-left-radius, 50px, (xs:0));
      @include res(padding, 45px 45px 35px 45px, (md:25px 25px 15px 35px, xs:15px));
    }
    .index-banner-news-title{
      color: $color-green;
      font-size: 14px;
      margin-bottom: 10px;
    }
    .index-banner-news-slide{
      .swiper-container{
        height: 50px;
      }
      .swiper-slide{
        a{
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          transition: $anime-duration $anime-bezier;
          color: #fff;
          line-height: 1.6;
          &:hover{
            color: $color-green;
          }
        }
      }
    }
  }
}

.section1{
  position: relative;
  z-index: 1;
  background-color: #fff;
  overflow: hidden;
  @include res(padding-top, 120px, 30 / 120);
  @include res(padding-bottom,140px, 40 / 140);
  .animate-circle{
    position: absolute;
    left: 0;
    // bottom: 0;
    // transform: translate3d(-50%,40%, 0);
    @include res(bottom,0,(sm:auto));
    @include res(top, auto, (sm:0));
    @include res(transform, translate3d(-50%, 40%, 0), (sm:translate3d(-50%, 10%, 0)));
    svg{
      @include res(width,600px,(md:500px,xs:400px));
      @include res(height, 600px, (md:500px, xs:400px));
      rect {
        stroke: $color-blue-light3;
        stroke-dasharray: 90%;
        stroke-dashoffset: 90%;
        @include res(stroke-width, 80, (md:60,xs:40));
        @include res(x, 40, (md:30, xs:20));
        @include res(y, 40, (md:30, xs:20));
        @include res(width, calc(100% - 80px), (md:calc(100% - 60px), xs:calc(100% - 40px)));
        @include res(height, calc(100% - 80px), (md:calc(100% - 60px), xs:calc(100% - 40px)));
      }
    }
    &.active{
      svg {
        rect {
          stroke-dashoffset: 0%;
        }
      }
    }
  }
  .logo-fixed-img{
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
    img{
      display: block;
      height: auto;
      @include res(width,null, (md:400px,xs:200px));
    }
  }
  .container{
    position: relative;
    z-index: 1;
    align-items: flex-start;
    justify-content: space-between;
    @include res(display,flex,(sm:block));
    .left{
      flex-shrink: 0;
      @include res(margin-bottom,0,(sm:20px,xs:10px));
      @include res(width,43.57%,(sm:100%));
      h1{
        font-weight: bold;
        color: $color-blue;
        overflow: hidden;
        @include res(text-align,left,(sm:center));
        @include res(margin-bottom,25px, 15 / 25);
        @include res(font-size, $title-size-lg50-base, $title-size-lg50-ratio);
      }
      .text{
        overflow: hidden;
      }
      .common-more-link{
        @include res(margin-top, 180px, (lg:100px, mmd:70px,md:50px,sm:30px,xs:20px));
        a{
          @include res(margin, null, (sm:0 auto));
        }
      }
    }
    .right {
      flex-shrink: 0;
      @include res(width, 50%, (sm:100%));
      img{
        display: block;
        width: 100%;
        height: auto;
        @include res(box-shadow, 20px 20px 0 $color-green, (sm:10px 10px 0 $color-green, xs:5px 5px 0 $color-green));
      }
    }
  }
}

.section2{
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: $color-blue;
  @include res(padding-top,110px, 50 / 110);
  @include res(padding-bottom, 110px, 40 / 110);
  .logo-fixed-img {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    transform: translateY(-100%);
    img{
      display: block;
      height: auto;
      @include res(width,null, (md:400px,xs:200px));
    }
  }
  .section2-title{
    font-weight: bold;
    color:#fff;
    position: relative;
    z-index: 1;
    overflow: hidden;
    @include res(margin-bottom, 40px, 20 / 40);
    @include res(font-size,$title-size-lg50-base,$title-size-lg50-ratio);
  }
  .section2-cont{
    align-items: stretch;
    @include res(display,flex,(sm:block));
    .section2-cont-item{
      flex-shrink: 0;
      background: $color-blue-light1;
      box-sizing: border-box;
      color: #fff;
      position: relative;
      z-index: 1;
      @include res(width,50%,(sm:100%));
      @include res(padding,80px, 30 / 80);
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        transition: $anime-duration $anime-bezier;
      }
      &.active{
        z-index: 2;
        &::after{
          background: $color-blue-light2;
          transform: scale(1.1);
          @include res(transform,scale(1.1),(sm:scale(1.05)));
        }
        .item-title {
          .right-icon{
            &::after{
              transform: scale(1);
            }
          }
        }
      }
      .item-title{
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include res(margin-bottom,50px, 25 / 50);
        .left-text{
          flex-shrink: 0;
          h1{
            font-weight: bold;
            @include res(font-size,$title-size-lg36-base,$title-size-lg36-ratio);
          }
          h2{
            color: rgba(255, 255, 255, .5);
            @include res(font-size,$text-size-md-base,$text-size-md-ratio);
          }
        }
        .right-icon{
          flex-shrink: 0;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          transition: $anime-duration $anime-bezier;
          @include res(width,90px, 60 / 90);
          @include res(height,90px, 60 / 90);
          &::before{
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            border-radius: 50%;
            border: solid #fff;
            @include res(border-width,2px,(xs:1px));
          }
          &::after{
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            background-color: $color-green;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            transform: scale(0);
            transform-origin: center;
            transition: $anime-duration $anime-bezier;
            border-radius: 50%;
          }
          img{
            position: relative;
            z-index: 2;
            height: auto;
            @include res(width,54px, 35 / 54);
          }
        }
      }
      .item-cont{
        position: relative;
        z-index: 1;
        ul{
          li{
            overflow: hidden;
            border-bottom: 1px solid rgba(255, 255, 255, .2);
            @include res(padding-top,15px, 7 / 15);
            @include res(padding-bottom,15px, 7 / 15);
            @include res(font-size,$text-size-md-base,$text-size-md-ratio);
            .iconfont{
              color: $color-green;
              margin-left: 10px;
              margin-right: 25px;
              @include res(font-size,24px, 20 / 24);
            }
          }
        }
      }
    }
  }
}

.section3{
  position: relative;
  z-index: 1;
  background: $color-blue-light3;
  @include res(padding-top,110px, 30 / 110);
  @include res(padding-bottom,220px, 0 / 220);
  .section3-img{
    display: block;
    top: 0;
    right: 0;
    z-index: 0;
    @include res(width,auto,(xs:100%));
    @include res(height,100%,(xs:auto));
    @include res(position,absolute,(xs:static));
  }
  .container{
    position: relative;
    z-index: 1;
  }
  .section3-title{
    color: $color-blue;
    font-weight: bold;
    @include res(font-size,$title-size-lg50-base,$title-size-lg50-ratio);
    @include res(margin-bottom,85px, 10 / 85);
  }
  .section3-cont{
    ul li{
      overflow: hidden;
      @include res(margin-bottom,40px, 10 / 40);
      .item-title{
        font-weight: bold;
        color: $color-blue;
        display: flex;
        align-items: center;
        @include res(font-size,$text-size-lg-base,$text-size-lg-ratio);
        .iconfont{
          color: $color-green;
          margin-right: 15px;
          margin-left: 3px;
          @include res(font-size,30px, 20 / 30);
        }
      }
    }
  }
}

.section4{
  position: relative;
  z-index: 1;
  background-color: #fff;
  @include res(padding-top,110px, 30 / 110);
  // @include res(padding-bottom,220px, 0 / 220);
  .section4-title{
    h1{
      color: $color-blue;
      font-weight: bold;
      @include res(font-size,$title-size-lg50-base,$title-size-lg50-ratio);
    }
    .common-more-link{
      @include res(margin-top,-35px, (sm:-45px));
      >div{
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .section4-cont{
    position: relative;
    @include res(margin-top,65px, 30 / 65);
    .section4-slide-control{
      width: 100%;
      top: 0;
      left: 0;
      @include res(position,absolute,(xs:static));
      .container{
        align-items: center;
        justify-content: space-between;
        @include res(flex-direction,row,(xs:row-reverse));
        @include res(display,block,(xs:flex));
      }
      .slide-arrow{
        display: flex;
        align-items: center;
        @include res(padding-top,35px,15 / 35);
        .swiper-button-next,.swiper-button-prev{
          color: #626262;
          font-size: 12px;
          left: auto;
          margin-top: 0;
          @include res(width,60px, 40 / 60);
          @include res(height,60px, 40 / 60);
          svg{
            rect{
              stroke: #d2d2d2;
            }
            rect+rect{
              stroke: $color-blue;
            }
          }
        }
        .swiper-button-next{
          &::after{
            content: '\e621';
            font-family: 'iconfont';
          }
        }
        .swiper-button-prev{
          margin-right: 25px;
          &::after{
            content: '\e8dc';
            font-family: 'iconfont';
          }
        }
      }
      .swiper-pagination{
        position: static;
        width: 100px;
        color: $color-blue;
        padding-left: 10px;
        text-align: left;
        @include res(margin-top,40px,(xs:0));
      }
    }
    .section4-slide-cont{
      @include res(margin-top,0,(xs:15px));
      @include res(padding-right, 0,(xs:10px));
      @include res(padding-left,490px,10 / 490);
      .swiper-slide{
        a{
          display: block;
          transition: $anime-duration $anime-bezier;
          @include res(padding,35px, 15 / 35);
          .item-img{
            position: relative;
            overflow: hidden;
            @include res(margin-bottom,40px,15 / 40);
            b{
              display: block;
              width: 100%;
              height: 100%;
              background-position: center center;
              background-size: cover;
              position: absolute;
              top: 0;
              left: 0;
              transition: all .3s;
            }
            img{
              display: block;
              width: 100%;
              height: auto;
              opacity: 0;
            }
          }
          .item-title{
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-weight: bold;
            color: $color-title;
            line-height: 1.33;
            transition: $anime-duration $anime-bezier;
            @include res(margin-bottom,15px, 8 / 15);
            @include res(font-size,$title-size-xs24-base,$title-size-xs24-ratio);
            @include res(min-height,62px,(lg:58px,mmd:46px,md:42px,xs:auto));
          }
          .item-date{
            font-size: 14px;
            color: $color-desc;
          }
          .item-text{
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            @include res(margin-top,10px, 5 / 10);
            @include res(min-height,96px,(lg:84px));
          }
        }
        &:hover{
          a{
            background: $color-blue-light3;
            .item-title{
              color: $color-blue;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1680px) {
  .section4 .section4-cont .section4-slide-cont{
    padding-left: 380px;
  }
}
@media screen and (max-width: 1600px) {
  .section4 .section4-cont .section4-slide-cont{
    padding-left: 400px;
  }
}
@media screen and (max-width: 1440px) {
  .section4 .section4-cont .section4-slide-cont{
    padding-left: 380px;
  }
}
@media screen and (max-width: 1366px) {
  .section4 .section4-cont .section4-slide-cont{
    padding-left: 340px;
  }
}
@media screen and (max-width: 1024px) {
  .section4 .section4-cont .section4-slide-cont{
    padding-left: 220px;
  }
}
@media screen and (max-width: 768px) {
  .section4 .section4-cont .section4-slide-cont{
    padding-left: 170px;
  }
}
@media screen and (max-width: 640px) {
  .section4 .section4-cont .section4-slide-cont{
    padding-left: 10px;
  }
}

//banner 指示点 圆形进度条
@keyframes progress {
  0% {
    stroke-dashoffset: 245px;
  }
  50% {
    stroke-dashoffset: 122.5px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes progress2 {
  0% {
    stroke-dashoffset: 182px;
  }
  50% {
    stroke-dashoffset: 91px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes progress3 {
  0% {
    stroke-dashoffset: 120px;
  }
  50% {
    stroke-dashoffset: 60px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
